export const images = {
   index_cover: require('./images/index_cover.png'),
   section_top: require('./images/section_top.png'),
   icon_arrow_bold_down: require('./images/icon_arrow_bold_down.png'),
   depression_01: require('./images/depression_01.png'),
   depression_02: require('./images/depression_02.png'),
   relax_tip_01: require('./images/relax_tip_01.png'),
   relax_tip_02: require('./images/relax_tip_02.png'),
   relax_item_01: require('./images/relax_item_01.png'),
   relax_item_02: require('./images/relax_item_02.png'),
   relax_item_03: require('./images/relax_item_03.png'),
   relax_item_04: require('./images/relax_item_04.png'),
   relax_item_05: require('./images/relax_item_05.png'),
   anger: require('./images/anger.png'),
   measure_item_01: require('./images/measure_item_01.png'),
   measure_item_02: require('./images/measure_item_02.png'),
   measure_item_03: require('./images/measure_item_03.png'),
   measure_item_04: require('./images/measure_item_04.png'),
   measure_item_05: require('./images/measure_item_05.png'),
   compare_title_item_01: require('./images/compare_title_item_01.png'),
   compare_title_item_02: require('./images/compare_title_item_02.png'),
   measure_item_06: require('./images/measure_item_06.png'),
   measure_item_07: require('./images/measure_item_07.png'),
   measure_item_08: require('./images/measure_item_08.png'),
   icon_music_green: require('./images/icon_music_green.svg'),
   icon_physical_green: require('./images/icon_physical_green.svg'),
   icon_space: require('./images/icon_space.svg'),
   icon_space_red: require('./images/icon_space_red.svg'),
   icon_space_green: require('./images/icon_space_green.svg'),
   icon_nature: require('./images/icon_nature.svg'),
   icon_nature_red: require('./images/icon_nature_red.svg'),
   icon_nature_green: require('./images/icon_nature_green.svg'),
   icon_friendly: require('./images/icon_friendly.svg'),
   icon_friendly_red: require('./images/icon_friendly_red.svg'),
   icon_friendly_green: require('./images/icon_friendly_green.svg'),
   icon_personal: require('./images/icon_personal.svg'),
   icon_personal_red: require('./images/icon_personal_red.svg'),
   icon_personal_green: require('./images/icon_personal_green.svg'),
   icon_physical: require('./images/icon_physical.svg'),
   icon_physical_red: require('./images/icon_physical_red.svg'),
   icon_lang: require('./images/icon_lang.svg'),
   icon_lang_green: require('./images/icon_lang_green.svg'),
   icon_lang_red: require('./images/icon_lang_red.svg'),
   icon_logic: require('./images/icon_logic.svg'),
   icon_logic_green: require('./images/icon_logic_green.svg'),
   icon_logic_red: require('./images/icon_logic_red.svg'),
   icon_music: require('./images/icon_music.svg'),
   icon_music_red: require('./images/icon_music_red.svg'),
   personality_type: require('./images/personality_type.png'),
   balance_center: require('./images/balance_center.png'),
   balance_right: require('./images/balance_right.png'),
   balance_left: require('./images/balance_left.png'),
   balance_core: require('./images/balance_core.png'),
   balance_of_power: require('./images/balance_of_power.png'),
   graph_wing: require('./images/graph_wing.png'),
   graph_arrow: require('./images/graph_arrow.png'),
   icon_ego_jc: require('./images/icon_ego_jc.png'),
   icon_ego_ec: require('./images/icon_ego_ec.png'),
   icon_ego_mr: require('./images/icon_ego_mr.png'),
   icon_ego_fe: require('./images/icon_ego_fe.png'),
   icon_ego_ae: require('./images/icon_ego_ae.png'),
   age_icon_0_on: require('./images/age_icon_0_on.png'),
   age_icon_1_on: require('./images/age_icon_1_on.png'),
   age_icon_2_on: require('./images/age_icon_2_on.png'),
   age_icon_3_on: require('./images/age_icon_3_on.png'),
   age_icon_4_on: require('./images/age_icon_4_on.png'),
   age_icon_5_on: require('./images/age_icon_5_on.png'),
   age_icon_6_on: require('./images/age_icon_6_on.png'),
   age_icon_7_on: require('./images/age_icon_7_on.png'),
   age_icon_1: require('./images/age_icon_1.png'),
   age_icon_2: require('./images/age_icon_2.png'),
   age_icon_3: require('./images/age_icon_3.png'),
   age_icon_4: require('./images/age_icon_4.png'),
   age_icon_5: require('./images/age_icon_5.png'),
   age_icon_6: require('./images/age_icon_6.png'),
   age_icon_7: require('./images/age_icon_7.png'),
   icon_life_score: require('./images/icon_life_score.png'),
   Reconstruction: require('./images/Reconstruction.png'),
   section_top_02: require('./images/section_top_02.png'),
   icon_result_score_01: require('./images/icon_result_score_01.png'),
   icon_result_score_02: require('./images/icon_result_score_02.png'),
   icon_result_score_03: require('./images/icon_result_score_03.png'),
   icon_result_score_04: require('./images/icon_result_score_04.png'),
   icon_result_score_01_active: require('./images/icon_result_score_01_active.png'),
   icon_result_score_02_active: require('./images/icon_result_score_02_active.png'),
   icon_result_score_03_active: require('./images/icon_result_score_03_active.png'),
   icon_result_score_04_active: require('./images/icon_result_score_04_active.png'),
   result_score_img_03: require('./images/result_score_img_03.png'),
   life_score_section_0: require('./images/life_score_section_0.png'),
   life_score_section_1: require('./images/life_score_section_1.png'),
   life_score_section_2: require('./images/life_score_section_2.png'),
   life_score_section_3: require('./images/life_score_section_3.png'),
   life_score_section_4: require('./images/life_score_section_4.png'),
   life_score_section_5: require('./images/life_score_section_5.png'),
   life_score_section_6: require('./images/life_score_section_6.png'),
   life_score_section_7: require('./images/life_score_section_7.png'),
   growth_strategy_01: require('./images/growth_strategy_01.png'),
   growth_strategy_02: require('./images/growth_strategy_02.png'),
   letter_cover: require('./images/letter_cover.png'),
   letter_bg: require('./images/letter_bg.png'),
   metaq_1: require('./images/metaq-1.png'),
   metaq_2: require('./images/metaq-2.png'),
   metaq_3: require('./images/metaq-3.png'),
   metaq_4: require('./images/metaq-4.png'),
   metaq_5: require('./images/metaq-5.png'),
   metaq_6: require('./images/metaq-6.png'),
   metaq_7: require('./images/metaq-7.png'),
   metaq_8: require('./images/metaq-8.png'),
   metaq_9: require('./images/metaq-9.png'),
   metaq_10: require('./images/metaq-10.png'),
   metaq_11: require('./images/metaq-11.png'),
   metaq_12: require('./images/metaq-12.png'),
   metaq_13: require('./images/metaq-13.png'),
   metaq_14: require('./images/metaq-14.png'),
   metaq_15: require('./images/metaq-15.png'),
   metaq_16: require('./images/metaq-16.png'),
   metaq_17: require('./images/metaq-17.png'),
}
